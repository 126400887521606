<template>
    <div>
        <b-card>
            <div slot="header">
                Pitts Controller
            </div>


            <strong>Event Mode: </strong><br>
            <span style="float: right">
                Event Mode: &nbsp;&nbsp;&nbsp;&nbsp; Off &nbsp;
                    <b-form-checkbox style="display: inline" v-model="toggle.eventMode" @change="switchPartyMode" switch/>
                    On
                </span>
            <br>
            <hr>

            <strong>House Camera Status: </strong><br>
            <span style="float: right">
                    House Camera Status: &nbsp;&nbsp;&nbsp;&nbsp;
                <span v-if="toggle.houseCameras === true" class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-success btn-block"
                                   style="display: inline">On</b-form-button>
                </span>
                <span v-else class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-danger btn-block"
                                   style="display: inline">Off</b-form-button>
                </span>
            </span>
            <br>
            <br>
            <span style="float: right">
                    House Toggle Status: &nbsp;&nbsp;&nbsp;&nbsp;
                <span v-if="toggle.houseToggle === true" class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-success btn-block"
                                   style="display: inline">On</b-form-button>
                </span>
                <span v-else class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-danger btn-block"
                                   style="display: inline">Off</b-form-button>
                </span>
            </span>
            <br>
            <br>
            <hr>

            <strong>Reboot FLIRs</strong><br>
            <span style="float: right">
                    Inner Gate FLIR: &nbsp;&nbsp;&nbsp;&nbsp;
                <span class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-warning btn-block"
                                   style="display: inline" @click="rebootFlir(1)">Reboot</b-form-button>
                </span>
            </span>
            <br>
            <br>
            <span style="float: right">
                    Back Road FLIR: &nbsp;&nbsp;&nbsp;&nbsp;
                <span class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-warning btn-block"
                                   style="display: inline" @click="rebootFlir(2)">Reboot</b-form-button>
                </span>
            </span>
            <br>
            <br>
            <span style="float: right">
                    Bobs Barn FLIR: &nbsp;&nbsp;&nbsp;&nbsp;
                <span class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-warning btn-block"
                                   style="display: inline" @click="rebootFlir(3)">Reboot</b-form-button>
                </span>
            </span>
            <br>
            <br>
            <span style="float: right">
                    Back Gate FLIR: &nbsp;&nbsp;&nbsp;&nbsp;
                <span class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-warning btn-block"
                                   style="display: inline" @click="rebootFlir(4)">Reboot</b-form-button>
                </span>
            </span>
            <br>
            <br>
            <span style="float: right">
                    Horse Stable FLIR: &nbsp;&nbsp;&nbsp;&nbsp;
                <span class="col-sm-4 col-md-2 col-xl col-6">
                    <b-form-button class="btn btn-square btn-warning btn-block"
                                   style="display: inline" @click="rebootFlir(5)">Reboot</b-form-button>
                </span>
            </span>
            <br>
            <br>
            <hr>

            <strong>Lights: </strong><br>
            <span style="float: right">
        Horse Stable Flood: &nbsp;&nbsp;&nbsp;&nbsp; Off &nbsp;
        <b-form-checkbox style="display: inline" v-model="toggle.horseStable" @change="switchLight(1)" switch/>
        On
      </span>
            <br>
            <span style="float: right">
        Inner Gate Flood: &nbsp;&nbsp;&nbsp;&nbsp; Off &nbsp;
        <b-form-checkbox style="display: inline;" v-model="toggle.innerGate" @change="switchLight(2)" switch/>
        On
      </span>
            <br>
            <span style="float: right">
        Bison Pasture Flood: &nbsp;&nbsp;&nbsp;&nbsp; Off &nbsp;
        <b-form-checkbox style="display: inline;" v-model="toggle.bisonPasture" @change="switchLight(3)" switch/>
        On
      </span>
            <br>
            <hr>


            <strong>Gate Alerts: </strong><br>
            <span v-for="(alert) in alerts.slice(0,7)" :key="alert.value">
        <span style="float: right">
          {{ name[alert.eventId] }}: &nbsp;&nbsp;&nbsp;&nbsp; Off &nbsp;
          <b-form-checkbox style="display: inline" v-model="alert.status" @change="toggleAlert(alert.eventId)" switch/>
          On
        </span>
        <br>
      </span>
            <hr>

            <strong>Door Alerts: </strong><br>
            <span v-for="(alert) in alerts.slice(7)" :key="alert.value">
        <span style="float: right">
          {{ name[alert.eventId] }}: &nbsp;&nbsp;&nbsp;&nbsp; Off &nbsp;
          <b-form-checkbox style="display: inline" v-model="alert.status" @change="toggleAlert(alert.eventId)" switch/>
          On
        </span>
        <br>
      </span>

        </b-card>

    </div>
</template>

<script>
import fcr from '@/services/fcr.js'

export default {
    name: "PartyMode",

    data: () => {
        return {
            eventModeStatus: 0,
            horseStableStatus: "off",
            innerGateStatus: "off",
            bisonPastureStatus: "off",
            toggle: {
                eventMode: false,
                horseStable: false,
                innerGate: false,
                bisonPasture: false,
                houseCameras: false,
                houseToggle: false
            },
            name: {
                172221351102: "Back Gate Card",
                172221351103: "Back Gate Intercom",
                172221351105: "Back Gate Button",
                172221351106: "Back Gate Motion",
                172221351112: "Front Gate Card",
                172221351113: "Front Gate Intercom",
                172221351117: "Front Gate Motion",
                zone1: "Front Door Main Entrance",
                zone2: "First Floor Rear Door",
                zone4: "Master Deck Left Door",
                zone5: "Master Deck Right Door",
                zone6: "First Floor Pool Door",
                zone7: "Pool Area Right Door",
                zone9: "First Floor Door",
                zone10: "First Floor Double Door",
                zone11: "Main Floor Single Deck Door",
                zone17: "Main Floor Bedroom Deck Door",
                zone18: "First Floor Piano Double Door",
                zone23: "Garage Main Door",
                zone24: "Garage Side Door",
                septicfailure: "Septic Failure"
            },
            alerts: []
        }
    },

    created() {
        this.getPartyMode();
        this.getLightStatus();
        this.getAlertStatus();
        this.getToggleStatus();
    },

    methods: {
        rebootFlir(num) {
            fcr.rebootFlir(num).then(response => {
                if (response.status === 200) {
                    this.$mToast({
                        title: 'Success',
                        text: "Rebooting!",
                        style: 'success'
                    });
                }
            });
        },
        getToggleStatus() {
            fcr.getToggleStatus().then(response => {
                this.toggle.houseToggle = response.data.toggle_status;
                this.toggle.houseCameras = response.data.camera_status;
            });
        },
        getAlertStatus() {
            fcr.getAlertStatus().then(response => {
                this.alerts = response.data;
            });
        },
        toggleAlert(event) {
            fcr.toggleAlertStatus(event).then(response => {
                this.alerts = response.data;
            });
        },
        getPartyMode() {
            fcr.getEventModeStatus().then(response1 => {
                this.eventModeStatus = response1.data.status;
                this.toggle.eventMode = response1.data.status === 1;
            })
        },
        getLightStatus() {
            fcr.getLightStatus(2).then(response => {
                this.innerGateStatus = response.data.status;
                this.toggle.innerGate = response.data.status !== 'off';
                fcr.getLightStatus(1).then(response1 => {
                    this.horseStableStatus = response1.data.status;
                    this.toggle.horseStable = response1.data.status !== 'off';
                    fcr.getLightStatus(3).then(response2 => {
                        this.bisonPastureStatus = response2.data.status;
                        this.toggle.bisonPasture = response2.data.status !== 'off';
                    });
                });
            });
        },
        switchLight(number) {
            if (number === 2 && this.innerGateStatus === "off") {
                fcr.turnLightOn(number).then(response => {
                    this.innerGateStatus = response.data.output;
                });
            } else if (number === 2) {
                fcr.turnLightOff(number).then(response1 => {
                    this.innerGateStatus = response1.data.output;
                });
            } else if (number === 1 && this.horseStableStatus === "off") {
                fcr.turnLightOn(number).then(response2 => {
                    this.horseStableStatus = response2.data.output;
                });
            } else if (number === 1) {
                fcr.turnLightOff(number).then(response3 => {
                    this.horseStableStatus = response3.data.output;
                })
            } else if (number === 3 && this.bisonPastureStatus === "off") {
                fcr.turnLightOn(number).then(response4 => {
                    this.bisonPastureStatus = response4.data.output;
                });
            } else if (number === 3) {
                fcr.turnLightOff(number).then(response5 => {
                    this.bisonPastureStatus = response5.data.output;
                })
            }
        },
        switchPartyMode() {
            if (this.eventModeStatus === 1) {
                fcr.turnEventModeOff().then(response => {
                    if (response.data.output !== 0) {
                        this.toggle.eventMode = true;
                    } else {
                        this.eventModeStatus = response.data.output;
                        fcr.playSound("eventmodedeactivated")
                    }
                });
            } else {
                fcr.turnEventModeOn().then(response2 => {
                    if (response2.data.output !== 1) {
                        this.toggle.eventMode = false;
                    } else {
                        this.eventModeStatus = response2.data.output;
                        fcr.playSound("eventmodeactivated")
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
hr {
    margin-bottom: 0;
    margin-top: 5px;
    color: #FFFF00;
    height: 1px;
}
</style>